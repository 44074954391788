import React from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import './List.scss';
import LetterNav from '../components/LetterNav';
import GenderNav from '../components/GenderNav';
import Search from '../components/Search';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
interface IProps {
    match: { params: any };
    history: any;
}

interface IState {
    origin: string;
    letter: string;
    gender: string;
    page: string;
    count: string;
    names: [];
}

class List extends React.Component<IProps, IState> {
    startsWith: string;
    endsWith: string;

    constructor(props: IProps) {
        super(props);
        this.state = { origin: 'All', gender: 'All', letter: 'A', page: '1', count: '20', names: [] };
        this.startsWith = 'startsWith';
        this.endsWith = 'endsWith';
    }

    static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
        let origin = 'All';
        let gender = 'All';
        let letter = 'A';
        let page = '1';
        let count = '20';

        if (nextProps.match.params.origin) {
            origin = nextProps.match.params.origin;
        }
        if (nextProps.match.params.letter) {
            letter = nextProps.match.params.letter;
        }
        if (nextProps.match.params.gender) {
            gender = nextProps.match.params.gender;
        }
        if (nextProps.match.params.page) {
            page = nextProps.match.params.page;
        }
        if (nextProps.match.params.count) {
            count = nextProps.match.params.count;
        }


        return { origin: origin, gender: gender, letter: letter, page: page, count: count };
    }

    componentDidMount() {
        this.GetAllNames();
    }

    componentDidUpdate(previousProps: IProps, previousState: IState) {
        if (previousProps.match.params.origin !== this.props.match.params.origin
            || previousProps.match.params.letter !== this.props.match.params.letter
            || previousProps.match.params.gender !== this.props.match.params.gender
            || previousProps.match.params.page !== this.props.match.params.page
            || previousProps.match.params.count !== this.props.match.params.count
        ) {
            this.GetAllNames();
        }
    }

    GetAllNames() {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API}/names/${this.state.origin}/${this.state.gender}/${this.state.letter}/${this.state.page}/${this.state.count}`
        })
            .then(({ data }) => {
                const names = data;
                this.setState({ names: names });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    render() {
        return (
            <div className="list">
                <Helmet>
                    <title>{`${this.state.origin} Baby Names for ${this.state.gender} starting with letter ${this.state.letter}>> page ${this.state.page}`}</title>
                    <meta name="description" content={`${this.state.origin} Baby Names for ${this.state.gender} starting with letter ${this.state.letter}>> page ${this.state.page}, BabyNamesPool, Largest pool of names for new borns..., Most popular names for boys and girls`} />
                    <meta name="keywords" content={`${this.state.origin}, Baby Names, ${this.state.gender}, letter ${this.state.letter}`} />
                </Helmet>
                <Header></Header>
                <section className="section center-wrapper">
                    <h1 className="section-title">{this.props.match.params.origin} Baby Names {this.props.match.params.letter}  </h1>
                    <div className="section-main">
                        <div className="letter-menu">
                            <Search history={this.props.history}></Search>
                            <GenderNav origin={this.state.origin} gender={this.state.gender} letter={this.state.letter}></GenderNav>
                            <LetterNav origin={this.state.origin} gender={this.state.gender} activeLetter={this.state.letter}></LetterNav>
                        </div>
                        <div className="main-wrapper">
                            <div className="names-section">
                                {/* <div className="FiltersSection">
                                    <div>
                                        <div>Starts With:</div>
                                        <div><input type="text"></input></div>
                                    </div>
                                    <div>
                                        <div>Ends With:</div>
                                        <div><input type="text"></input></div>
                                    </div>
                                </div> */}
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Meaning</th>
                                            <th>Gender</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.names.map((bn: any) =>
                                            <tr key={bn.Id}>
                                                <td><Link className="name-link" to={`/name/${bn.Name}`}>{bn.Name} </Link></td>
                                                <td>{bn.Meaning}</td>
                                                <td>{bn.Gender}</td>
                                            </tr>)}
                                    </tbody>
                                </table>
                                <div className="pagination-section">
                                    <div className="prev-link">
                                        <Link to={`/boy-girl-baby-names-starting-with/${this.state.origin}/${this.state.gender}/${this.state.letter}/${parseInt(this.state.page) > 1 ? (parseInt(this.state.page) - 1) : 1}/${this.state.count}`}> &lt;&lt; Prev </Link>
                                    </div>
                                    <div className="next-link">
                                        <Link to={`/boy-girl-baby-names-starting-with/${this.state.origin}/${this.state.gender}/${this.state.letter}/${parseInt(this.state.page) + 1}/${this.state.count}`}>  Next &gt;&gt; </Link>
                                    </div>
                                </div>
                            </div>
                            <aside className="adds-section">
                                <div className="aside-add">
                                    {/* <img src="https://media.giphy.com/media/lAt3J0hIlb9BK/giphy.gif" alt="gif-dancing" width="90%"></img>
                                    <img src="https://media.giphy.com/media/fJ7dzAwr7dCbm/giphy.gif" alt="gif-dancing" width="90%"></img>
                                    <img src="https://media.giphy.com/media/IqojY0rgVWfOE/giphy.gif" alt="gif-dancing" width="90%"></img> */}
                                </div>
                            </aside>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        );
    }
}

export default List;
