import React from 'react';
import './Search.scss';
import axios from 'axios';
interface IProps {
    history: any; 
}

interface IState {
  
}

class Search extends React.Component<IProps, IState>  {
    name: string;

    constructor(props:IProps) {
        super(props);
        this.name = '';
    }

    isNameExist() {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API}/names/isexist/${this.name}`
        })
            .then(({ data }) => {
              if(data){
                this.props.history.push(`/name/${this.name}`)
              }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    searchName(event: any) {
        this.isNameExist();
    }

    nameChange(event: any) {
        this.name = event.target.value;
    }

    render() {
        return (
            <div className="input-container">
                <input type="text" placeholder="Search Name" onChange={(e) => this.nameChange(e)} />
                <button type="button" onClick={(e) => this.searchName(e)}>Search</button>
            </div>
        );
    }
}

export default Search;
