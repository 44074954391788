import React from 'react';
import ReactDOM from 'react-dom';
import './design-tokens/master.scss';
import './index.scss';
import List from './pages/List';
import Home from './pages/Home';
import Name from './pages/Name'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <Switch>
            <Route path='/' exact render = {props => <Home {...props}/>}/>
            <Route path='/boy-girl-baby-names-starting-with/:origin?/:gender?/:letter?/:page?/:count?' render = {props => <List {...props}/>}/>
            <Route path='/name/:name?' render = {props => <Name {...props}/>}/>
        </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
