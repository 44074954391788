import React from 'react';
import { Link } from 'react-router-dom';
import './LetterNav.scss';
interface IProps{
    origin: string;
    gender:string;
    activeLetter: string;
}

class LetterNav extends React.Component<IProps> {
    render() {
        return (
            <nav className="LetterNav">
                <ul>
                    {
                        
                        "ABCDEFGHIJKLM".split("").map(letter => 
                             <li key={letter}><Link to={`/boy-girl-baby-names-starting-with/${this.props.origin}/${this.props.gender}/${letter}`} className={`${letter.toLowerCase()===this.props.activeLetter.toLowerCase()?'Active':''}`}> {letter} </Link></li>
                         ) 
                    }
                </ul>
                <ul>
                    {
                        
                        "NOPQRSTUVWXYZ".split("").map(letter => 
                             <li key={letter}><Link to={`/boy-girl-baby-names-starting-with/${this.props.origin}/${this.props.gender}/${letter}`} className={`${letter.toLowerCase()===this.props.activeLetter.toLowerCase()?'Active':''}`}> {letter} </Link></li>
                         ) 
                    }
                </ul>
            </nav>
        );
    }
}

export default LetterNav;
