import React from 'react';
import { Helmet } from "react-helmet";
import './Home.scss';
import Search from '../components/Search';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
interface IProps {
    history: any;
}

class Home extends React.Component<IProps> {

    render() {
        return (
            <div className="home">
                <Helmet>
                    <title>Baby Names for boy and girls</title>
                    <meta name="description" content={`BabyNamesPool, Largest pool of names for new borns..., Most popular names for boys and girls`} />
                    <meta name="keywords" content={`Baby Names, boy baby names, girl baby names, starting with letter`} />
                </Helmet>
                <Header></Header>
                <section className="section center-wrapper">
                    <h1 className="section-title"> Baby Names by origin </h1>
                    <div className="section-main">
                        <div className="history">
                            <Search history={this.props.history}></Search>
                        </div>
                        <div className="main-wrapper">
                            <div className="names-section">
                                <ul>
                                    <li key='English'><Link to='/boy-girl-baby-names-starting-with/English' title='English Baby Names'>English Baby Names</Link></li>
                                    <li key='Arabic'><Link to='/boy-girl-baby-names-starting-with/Arabic' title='Arabic Baby Names'>Arabic Baby Names</Link></li>
                                    <li key='Greek'><Link to='/boy-girl-baby-names-starting-with/Greek' title='Greek Baby Names'>Greek Baby Names</Link></li>
                                    <li key='Hebrew'><Link to='/boy-girl-baby-names-starting-with/Hebrew' title='Hebrew Baby Names'>Hebrew Baby Names</Link></li>
                                    <li key='Biblical'><Link to='/boy-girl-baby-names-starting-with/Biblical' title='Biblical Baby Names'>Biblical Baby Names</Link></li>
                                    <li key='Latin'><Link to='/boy-girl-baby-names-starting-with/Latin' title='Latin Baby Names'>Latin Baby Names</Link></li>
                                    <li key='French'><Link to='/boy-girl-baby-names-starting-with/French'  title='French Baby Names'>French Baby Names</Link></li>
                                    <li key='German'><Link to='/boy-girl-baby-names-starting-with/German' title='German Baby Names'>German Baby Names</Link></li>
                                    <li key='Indian'><Link to='/boy-girl-baby-names-starting-with/Indian' title='Indian Baby Names'>Indian Baby Names</Link></li>
                                    <li key='Sanskrit'><Link to='/boy-girl-baby-names-starting-with/Sanskrit' title='Sanskrit Baby Names'>Sanskrit Baby Names</Link></li>
                                </ul>
                            </div>
                            <aside className="adds-section">
                                <div className="aside-add">
                                    {/* <img src="https://media.giphy.com/media/lAt3J0hIlb9BK/giphy.gif" alt="gif-dancing" width="90%"></img>
                                    <img src="https://media.giphy.com/media/fJ7dzAwr7dCbm/giphy.gif" alt="gif-dancing" width="90%"></img>
                                    <img src="https://media.giphy.com/media/IqojY0rgVWfOE/giphy.gif" alt="gif-dancing" width="90%"></img> */}
                                </div>
                            </aside>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        );
    }
}

export default Home;
