import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer center-wrapper">
                <div className="footer-add">
                </div>
                <div className="footer-logo">
                    <span className="logo"><Link to='/'>BabyNamesPool</Link></span>
                    <span className="logo-tag">Largest pool of names for new borns...</span>
                </div>
                <div className="terms-and-conditions">
                    All site content ©2020 TEKvedha solutions LLC - Toronto, CA, All Rights Reserved
            </div>
            </footer>
        );
    }
}

export default Footer;
