import React from 'react';
import { Link } from 'react-router-dom';
import './GenderNav.scss';
interface IProps {
    origin: string;
    gender: string;
    letter: string;
}

class GenderNav extends React.Component<IProps> {
    render() {
        return (
            <nav className="gender-menu">
                <ul>
                    
                    <li key='All'><Link to={`/boy-girl-baby-names-starting-with/${this.props.origin}/All/${this.props.letter}`} className={`${this.props.gender === 'All' ? 'active' : ''}`} > All </Link></li>
                    <li key='Boy'><Link to={`/boy-girl-baby-names-starting-with/${this.props.origin}/Boy/${this.props.letter}`} className={`${this.props.gender === 'Boy' ? 'active' : ''}`}> Boy </Link></li>
                    <li key='Girl'><Link to={`/boy-girl-baby-names-starting-with/${this.props.origin}/Girl/${this.props.letter}`} className={`${this.props.gender === 'Girl' ? 'active' : ''}`}> Girl </Link></li>
                    <li key='UniSex'><Link to={`/boy-girl-baby-names-starting-with/${this.props.origin}/UniSex/${this.props.letter}`} className={`${this.props.gender === 'UniSex' ? 'active' : ''}`}> UniSex </Link></li>
                </ul>
            </nav>
        );
    }
}

export default GenderNav;
