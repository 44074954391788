import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';

class Header extends React.Component {
    render() {
        return (
            <header className="header center-wrapper">
                <div className="header-logo">
                    <span className="logo"><Link to='/'>BabyNamesPool</Link></span>
                    <span className="logo-tag">Largest pool of names for new borns...</span>
                </div>
                <div className="header-menu">
                    <ul className="menu">
                        <li className="menu-item" key='English' ><Link to='/boy-girl-baby-names-starting-with/English' title='English Baby Names' className="menu-link">English</Link></li>
                        <li className="menu-item" key='Arabic' ><Link to='/boy-girl-baby-names-starting-with/Arabic' title='Arabic Baby Names'className="menu-link">Arabic</Link></li>
                        <li className="menu-item" key='Greek' ><Link to='/boy-girl-baby-names-starting-with/Greek' title='Greek Baby Names' className="menu-link">Greek</Link></li>
                        <li className="menu-item" key='Hebrew' ><Link to='/boy-girl-baby-names-starting-with/Hebrew' title='Hebrew Baby Names'  className="menu-link">Hebrew</Link></li>
                        <li className="menu-item" key='Biblical' ><Link to='/boy-girl-baby-names-starting-with/Biblical' title='Biblical Baby Names'  className="menu-link">Biblical</Link></li>
                        <li className="menu-item" key='Latin' ><Link to='/boy-girl-baby-names-starting-with/Latin' title='Latin Baby Names'  className="menu-link">Latin</Link></li>
                        <li className="menu-item" key='French' ><Link to='/boy-girl-baby-names-starting-with/French'  title='French Baby Names' className="menu-link">French</Link></li>
                        <li className="menu-item" key='German' ><Link to='/boy-girl-baby-names-starting-with/German' title='German Baby Names'  className="menu-link">German</Link></li>
                        <li className="menu-item" key='Indian' ><Link to='/boy-girl-baby-names-starting-with/Indian' title='Indian Baby Names'  className="menu-link">Indian</Link></li>
                        <li className="menu-item" key='Sanskrit' ><Link to='/boy-girl-baby-names-starting-with/Sanskrit' title='Sanskrit Baby Names'  className="menu-link">Sanskrit</Link></li>
                        {/* <li className="menu-item">
                        <a className="menu-link" href="#123">hover us &gt;</a>
                        <ul className="sub-menu">
                            <li className="sub-menu-item"><a className="sub-menu-link" href="#123">123 us</a></li>
                            <li className="sub-menu-item"><a className="sub-menu-link" href="#123">123 us</a></li>
                            <li className="sub-menu-item"><a className="sub-menu-link" href="#123">123 us</a></li>
                            <li className="sub-menu-item"><a className="sub-menu-link" href="#123">123 us</a></li>
                            <li className="sub-menu-item"><a className="sub-menu-link" href="#123">123 us</a></li>
                            <li className="sub-menu-item"><a className="sub-menu-link" href="#123">123 us</a></li>
                        </ul>
                    </li> */}
                    </ul>
                </div>
            </header>
        );
    }
}

export default Header;
