import React from 'react';
import axios from 'axios';
import './Name.scss';
import Footer from '../components/Footer';
import LetterNav from '../components/LetterNav';
import Header from '../components/Header';
import Search from '../components/Search'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
interface IProps {
    match: { params: any };
    history: any;
}

interface IState {
    name: string;
    names: IName[];
    similarNames: string[];
    masterMeaning: string;
    masterOrigin: string;
    masterVariation: string;
    masterGender: string;
}

interface IName {
    Origin: string;
    Gender: string;
    Name: string;
    Meaning: string;
    StartingLetter: string;
    Pronounciation: string;
    Script: string;
    Variations: string;
}

class Name extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { name: '', names: [], masterMeaning: '', masterOrigin: '', masterVariation: '', masterGender: '', similarNames: [] };
    }

    static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
        let name = '';

        if (nextProps.match.params.name) {
            name = nextProps.match.params.name;
        }
        return { name: name };
    }

    componentDidMount() {
        this.GetName();
        this.GetSimilarBabyNames();
    }

    componentDidUpdate(previousProps: IProps, previousState: IState) {
        if (previousProps.match.params.name !== this.props.match.params.name
        ) {
            this.GetName();
            this.GetSimilarBabyNames();
        }
    }

    GetName() {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API}/names/${this.state.name}`
        })
            .then(({ data }) => {
                var names: IName[] = data;
                var meaningsSet = new Set(names.map(n => n.Meaning));
                let masterMeaning = '';
                meaningsSet.forEach(function (elem) {
                    masterMeaning += elem + ', '
                });

                var originsSet = new Set(names.map(n => n.Origin));
                let masterOrigin = '';
                originsSet.forEach(function (elem) {
                    masterOrigin += elem + ', '
                });

                var variationSet = new Set(names.map(n => n.Variations));
                let masterVariation = '';
                variationSet.forEach(function (elem) {
                    masterVariation += elem + ', '
                });

                var genderSet = new Set(names.map(n => n.Gender));
                let masterGender = '';
                genderSet.forEach(function (elem) {
                    masterGender += elem + ', '
                });

                this.setState({
                    names: names
                    , masterMeaning: masterMeaning.replace(/,\s*$/, "")
                    , masterOrigin: masterOrigin.replace(/,\s*$/, "")
                    , masterVariation: masterVariation.replace(/,\s*$/, "")
                    , masterGender: masterGender.replace(/,\s*$/, "")
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    GetSimilarBabyNames() {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API}/names/similar/${this.state.name}`
        })
            .then(({ data }) => {
                const names = data;
                this.setState({ similarNames: names });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    render() {
        return (
            <div className="list">
                <Helmet>
                    <title>{`${this.state.name} - ${this.state.masterMeaning} | BabyNamesPool`}</title>
                    <meta name="description" content={` Meaning for the name ${this.state.name} is ${this.state.masterMeaning}, ${this.state.name} is derived from the ${this.state.masterOrigin} origins`} />
                    <meta name="keywords" content={` ${this.state.name}, ${this.state.masterMeaning}, ${this.state.masterOrigin}, ${this.state.masterGender}`} />
                </Helmet>
                <Header></Header>
                <section className="section center-wrapper">
                    <div className="section-main">
                        <div className="input-container">
                            {/* <input type="text" placeholder="Starts with" onChange={(e) => this.startsWithChange(e)} />
                            <input type="text" placeholder="Ends with" onChange={(e) => this.endsWithChange(e)} />
                            <button type="button" onClick={(e) => this.searchName(e)}>Search</button> */}
                        </div>
                        <div className="letter-menu">
                            <Search history={this.props.history}></Search>
                            <LetterNav origin='All' gender='All' activeLetter='All'></LetterNav>

                        </div>
                        <div className="main-wrapper">
                            <div className="names-section">
                                <button className="goback-link" onClick={(e) => this.props.history.goBack()}>Go Back</button>
                                <h1>{this.props.match.params.name} </h1>
                                <p><strong>Meaning: </strong> {this.state.masterMeaning} </p>
                                <p><strong>Origin: </strong> {this.state.masterOrigin}</p>
                                <p><strong>Gender: </strong> {this.state.masterGender}</p>
                                {this.state.masterVariation && <p><strong>Variations: </strong> {this.state.masterVariation}</p>}
                                <p><strong>Description: </strong>
                                    The meaning of name {this.state.name} is {this.state.masterMeaning} and is primarly from {this.state.masterOrigin} origin(s).
                                The name {this.state.name} is used to name {this.state.masterGender} by parents. </p>
                                <div>
                                    <h3>Names similar to -  {this.props.match.params.name} </h3>
                                    {this.state.similarNames.map(name => <div className="similiar-name"> <Link className="name-link" to={`/name/${name}`}>{name}</Link> </div>)}
                                </div>
                            </div>

                            <aside className="adds-section">
                                <div className="aside-add">
                                    {/* <img src="https://media.giphy.com/media/lAt3J0hIlb9BK/giphy.gif" alt="gif-dancing" width="90%"></img>
                                    <img src="https://media.giphy.com/media/fJ7dzAwr7dCbm/giphy.gif" alt="gif-dancing" width="90%"></img>
                                    <img src="https://media.giphy.com/media/IqojY0rgVWfOE/giphy.gif" alt="gif-dancing" width="90%"></img> */}
                                </div>
                            </aside>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        );
    }
}

export default Name;
